import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";
import { IHospitalReview } from "../models/HospitalReview";

export default {
  insert(review: IHospitalReview) {
    return AuthAxios.post(ApiURIs.HospitalReviewInsert, JSON.stringify(review));
  },

  update(review: IHospitalReview) {
    return AuthAxios.put(ApiURIs.HospitalReviewUpdate, JSON.stringify(review));
  },

  GetReviewByCurrentUser(hospitalId: number) {
    return AuthAxios.get(
      `${ApiURIs.HospitalReviewByUser}?hospitalId=${hospitalId}`
    );
  },

  getDoctorReview(hospitalId: number) {
    return AuthAxios.get(`${ApiURIs.HospitalReview}?hospitalId=${hospitalId}`);
  },

  getDoctorReviewUserInfo(doctorId: number) {
    return AuthAxios.get(
      `${ApiURIs.DrGetDoctorReviewUserInfo}?doctorId=${doctorId}`
    );
  },

  GetReviewByCurrentDoctor() {
    return AuthAxios.get(ApiURIs.DrReviewGetListByDoctor);
  },

  delete(id: number) {
    return AuthAxios.delete(`${ApiURIs.DrReviewDelete}/${id}`);
  }
};
