







































































































import { Vue, Component, Prop } from "vue-property-decorator";
import ProfileWidget from "@/common-app/components/ProfileWidget.vue";
import { IHospitalDetail } from "../models/HospitalDetail";
import { IHospitalReview } from "../models/HospitalReview";
import HospitalService from "../service/HospitalService";
import HospitalReviewService from "../service/HospitalReviewService";

import AuthUser from "../../global-config/AuthUser";

@Component({
  components: {
    ProfileWidget,
  },
})
export default class Details extends Vue {
  public revirew: IHospitalReview = {
    rating: 0,
    isFavourite: false,
  } as IHospitalReview;

  // public isShowAppoitment: boolean = false;
  public detail: IHospitalDetail = {} as IHospitalDetail;

  public profile: any = {};
  public oldRating: number;

  created() {
    this.profile = AuthUser.getFullName() || {};

    HospitalService.GetDetailsById(Number(this.$route.params.id || 0)).then((result) => {
      this.detail = result.data;

      this.revirew = Object.assign(this.revirew, {
        hospitalId: Number(this.$route.params.id || 0),
      });
      /*
      this.profile = s.data.profile || {};
  */
    });

    HospitalReviewService.GetReviewByCurrentUser(Number(this.$route.params.id || 0)).then((res) => {
      this.revirew = res.data.result || this.revirew;
      this.revirew = Object.assign(this.revirew, {
        hospitalId: Number(this.$route.params.id || 0),
      });
      this.oldRating = this.revirew.rating;
    });

    HospitalReviewService.getDoctorReview(Number(this.$route.params.id || 0)).then((res) => {
      this.$store.dispatch("AddRating", res.data.result.rating || 0);
      this.$store.dispatch("AddFavourite", res.data.result.favourite || 0);
    });
  }

  ratingSubmit() {
    if (this.revirew.id > 0) {
      HospitalReviewService.update(this.revirew).then((res) => {
        this.revirew = res.data.result || this.revirew;

        let storerating = this.$store.getters.rating - this.oldRating;
        this.$store.dispatch("AddRating", storerating + this.revirew.rating);
        //this.oldRating = this.revirew.rating;

        alert("Rating Add Success");
      });
    } else {
      HospitalReviewService.insert(this.revirew).then((res) => {
        this.revirew = res.data.result || this.revirew;

        let storerating = this.$store.getters.rating;
        this.$store.dispatch("AddRating", storerating + this.revirew.rating);

        alert("Rating Add Success");
      });
    }
  }
  handleChange(e: any) {
    HospitalReviewService.update(Object.assign(this.revirew, { isFavourite: e.target.checked })).then((res) => {
      this.revirew = res.data.result || this.revirew;
      let fav = this.$store.getters.favourite;
      if (e.target.checked) {
        this.$store.dispatch("AddFavourite", fav + 1);
        alert("Favourite Add Success");
      } else {
        this.$store.dispatch("AddFavourite", fav - 1);
        alert("Fevourite remove");
      }
    });
  }
}
